(function () {
  'use strict';

  class Discussions {
    constructor(DiscussionsRest, $cookies, $rootScope) {
      this.DiscussionsRest = DiscussionsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    discussionsCreate(discussions = {}, callback = angular.noop) {
      return this.DiscussionsRest.discussions().save({discussion: discussions},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    discussionView(discussion = {}, callback = angular.noop) {
      if (discussion.classroom_id) {
        discussion.classroom = 'classroom';
      }
      return this.DiscussionsRest.discussion().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    activeDiscussionView(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.activeDiscussions().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entriesView(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.entries().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entriesModule(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.entriesModule().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entriesAllView(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.entriesAll().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    latest(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.latest().get(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    discussionEdit(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.discussion().update(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    discussionDelete(discussion = {}, callback = angular.noop) {
      return this.DiscussionsRest.discussions().remove(discussion,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entriesCreate(entries = {}, callback = angular.noop) {
      return this.DiscussionsRest.entries().save({discussion_entry: entries},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entryEdit(entry = {}, callback = angular.noop) {
      return this.DiscussionsRest.entry().update(entry,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    entryDelete(entry = {}, callback = angular.noop) {
      return this.DiscussionsRest.entry().delete(entry,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Discussions
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Discussions', Discussions);
}());
